import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'


export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  //fileter
  const deleteFilter = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label:'員工編號',sortable: true },
    { key: 'name',stickyColumn: true, label:'姓名',sortable: true },
    { key: 'email', label:'電郵', sortable:true},
    { key: 'role', label:'用戶分類'},
    { key: 'team_id', label:'團隊'},
/*     { key: 'team_id', label:'Team', sortable:true},
    { key: 'is_teamleader', label:'Team Leader', sortable:true}, */
/*     { key: 'day_of_employment', label: '入職日期', sortable: true },
  { key: 'annual_leave', label: '年假天數', sortable: true },
  { key: 'current_employment_cycle', label: '當前工作周期' },
  { key: 'end_current_employment_cycle', label: '當前工作周期結束日' },
  { key: 'day_left', label: '剩餘天數' },
  { key: 'annual_leave_balance', label: '年假餘額' }, */
  { key: 'is_active', label: '狀態' },
  { key: 'actions', label: '操作' },
  ]

  const tableReportColumns = [
    { key: 'id', label:'員工編號',sortable: true },
    { key: 'name',label:'姓名中文',sortable: true },
    { key: 'name_en',label:'姓名英文',sortable: true },
    { key: 'gender', label:'姓別', sortable:true},
    { key: 'email', label:'電郵', sortable:true},
    { key: 'day_of_employment', label: '入職日期', sortable: true },
    { key: 'last_day_of_employment', label: '離職日期', sortable: true },
    { key: 'is_active', label: '狀態' },
  ];

  const perPage = ref(50)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter, deleteFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        is_active: statusFilter.value,
        is_deleted: deleteFilter.value
      })
      .then(response => {
        const { users, total } = response.data
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    ability,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    deleteFilter,
    tableReportColumns
  }
}
