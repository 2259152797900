import axios from '@axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    exportUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/reports/users/export', {
          params: queryParams,
          responseType: 'blob',
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/user/list', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/user/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
         axiosIns.post('/user', userData)
         .then(response=>resolve(response))
         .catch(error=>reject(error))
      })
    },
    fetchTeams(ctx) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/team/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchPositions(ctx) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/position/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
          axiosIns.get('/role/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
          axiosIns.get('/role', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setUserRole(ctx, data) {
      return new Promise((resolve, reject) => {
          axiosIns.post('/role/setUserRole', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    restoreUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .patch(`/user/${id}/restore`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/user", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
}
